import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/banks/*" />
    <RAC path="/zh-cn/banks/*" />
    <RAC path="/zh-tw/banks/*" />
    <RAC path="/pl/banks/*" />
    <RAC path="/ru/banks/*" />
    <RAC path="/it/banks/*" />
    <RAC path="/de/banks/*" />
    <RAC path="/es/banks/*" />
    <RAC path="/fr/banks/*" />
    <RAC path="/ar/banks/*" />
    <RAC path="/id/banks/*" />
    <RAC path="/pt/banks/*" />
    <RAC path="/ko/banks/*" />
    <DynamicNotFoundPage default />
  </Router>
)
